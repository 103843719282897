<template>
  <div>
    <BaseTable
      :headers="headers"
      :loading="loading"
      :paginate="true"
      :items="items"
      sort-by="id_tipo_meta"
      class="table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Tipos de Metas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="601"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro @selectedFilters="fetchTipoMeta((filters = $event))" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip :color="statusAtivo[item.ativo].color" dark>
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.tipo_pagamento`]="{ item }">
        <v-chip :color="statusAtivo[item.tipo_pagamento].color" dark>
          {{ statusAtivo[item.tipo_pagamento].text }}
        </v-chip>
      </template>

      <template v-slot:[`item.tipo_metas`]="{ item }">
        <v-chip :color="statusAtivo[item.tipo_metas].color" dark>
          {{ statusAtivo[item.tipo_metas].text }}
        </v-chip>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!--  <v-btn
          icon
          v-can-access="602"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="603"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="602" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="603"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalTipoMeta
      :tipoMeta="meta"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import statusAtivo from "@/mixins/statusAtivo";
import campanha from "@/services/http/campanhaService";
import Filtro from "./Filtro";
import ModalTipoMeta from "./ModalTipoMeta";

export default {
  name: "TabelaTipoMeta",
  mixins: [statusAtivo],
  components: {
    RegisterBottom,
    BaseTable,
    ModalTipoMeta,
    Filtro,
    IconBottom
  },

  data() {
    return {
      filters: {},
      labelBtn: "",
      dialog: false,
      meta: {},
      headers: [
        { text: "Tipo Meta", value: "tipo_meta" },
        { text: "Detalhes", value: "detalhes" },
        { text: "Status", value: "ativo", align: "right" },
        { text: "Tipo Pagamento", value: "tipo_pagamento", align: "right" },
        { text: "Tipo Metas", value: "tipo_metas", align: "right" },
        { text: "Ações", value: "acoes" }
      ],
      items: [],
      loading: false
    };
  },

  methods: {
    editItem(item) {
      this.meta = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    // statusFormated(tipoMeta, status, tipoPagamento) {
    //   return {
    //     color: tipoMeta[status] === "S" ? "green" : "red",
    //     text: tipoMeta[status] === "S" ? "Ativo" : "Inativo"
    //   };
    // },

    async fetchTipoMeta(filters) {
      this.loading = true;
      const { data } = await campanha()
        .tipoMeta()
        .show({
          per_page: -1,
          ...filters
        });
      this.items = data.data;
      this.loading = false;
    },
    refresh() {
      this.dialog = false;
      this.fetchTipoMeta(this.filters);
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar o tipo meta?",
          "Essa ação não pode ser desfeita"
        );
        campanha()
          .tipoMeta(item.id_tipo_meta)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
        this.refresh();
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchTipoMeta();
      }
    }
  },

  async mounted() {
    await this.fetchTipoMeta();
  }
};
</script>
